import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { Navbar, Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import http from '../_services/http-common';

import { ProfileContext } from '../_context/ProfileContext';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const MainHeader = () => {


    const logmeout = e => {

        e.preventDefault();

        http.delete('ess/login/logout').then(response => {
            // console.log(response.data);
            // localStorage.removeItem('auth');
            // localStorage.removeItem('api_key');
            localStorage.clear();
            window.location.replace('/');
            setProfile({});
        }).catch(error => {
            console.log(error);
        });

    }

    const [profile, setProfile] = useContext(ProfileContext);

    // console.log(profile);

    let isAuth = localStorage.getItem('auth');

    let navigation_panel = '';

    if (isAuth) {
        navigation_panel = (<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
            <Container>
                <Navbar.Brand href="/">
                    {/* <img
                    alt=""
                    src="AppImages/brand/avatar6.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                /> */}

                    {(profile.photo_uri != '') ?
                        <img className="d-inline-block align-top" src={profile.photo_uri} width="30"
                            style={{ marginRight: '7px', border: '1px solid #f2f2f2', borderRadius: '50%' }} />
                        :
                        ''
                    }

                    {profile.name_view}</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <NavLink className={'nav-link'} to='/attendance'><FontAwesomeIcon icon="fa-solid fa-clipboard-user" /> Absensi</NavLink>
                        {/* {(profile.cuti_type == 'Tahunan') ? <NavLink className={'nav-link'} to='/permissions'><FontAwesomeIcon icon="fa-solid fa-file-pen" /> Perizinan</NavLink> : ''} */}
                        <NavLink className={'nav-link'} to='/permissions'><FontAwesomeIcon icon="fa-solid fa-file-pen" /> Perizinan</NavLink>
                        {(parseInt(profile.count_manager_id) > 0) ? <NavLink className={'nav-link'} to='/listpermissions'><FontAwesomeIcon icon="fa-solid fa-list-check" /> Perizinan (Team)</NavLink> : ''}
                        <NavLink className={'nav-link'} to='/profile'><FontAwesomeIcon icon="fa-solid fa-user-tie" /> Profil</NavLink>
                    </Nav>
                    <Nav>
                        <NavLink className={'nav-link'} to='/announcement'><FontAwesomeIcon icon="fa-solid fa-bullhorn" /> Pengumuman</NavLink>
                        <NavLink className={'nav-link'} to='/suggestions'><FontAwesomeIcon icon="fa-solid fa-box-open" /> Kotak Saran</NavLink>
                        <Nav.Link href="#" onClick={logmeout}><FontAwesomeIcon icon="fa-solid fa-right-from-bracket" /> Log Out</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>);
    }

    return (<div><ToastContainer />{navigation_panel}</div>);
    // return (<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    //     <Container>
    //         <Navbar.Brand href="#home">ini : {value}</Navbar.Brand>
    //         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //         <Navbar.Collapse id="responsive-navbar-nav">
    //             <Nav className="me-auto">
    //                 <NavLink className={'nav-link'} to='/welcome'><FontAwesomeIcon icon="fa-solid fa-clipboard-user" /> Attendance</NavLink>
    //                 <NavLink className={'nav-link'} to='/products'><FontAwesomeIcon icon="fa-solid fa-file-pen" /> Permissions</NavLink>
    //                 <NavLink className={'nav-link'} to='/products'><FontAwesomeIcon icon="fa-solid fa-user-tie" /> Profile</NavLink>
    //             </Nav>
    //             <Nav>
    //                 <Nav.Link href="#" onClick={logmeout}><FontAwesomeIcon icon="fa-solid fa-right-from-bracket" /> Log Out</Nav.Link>
    //             </Nav>
    //         </Navbar.Collapse>
    //     </Container>
    // </Navbar>);
};

export default MainHeader;