import React, { useState, useContext } from 'react';
import { Table, Container, Button, Row, Col, Image, Modal, FloatingLabel, Form, Card, Badge } from "react-bootstrap";
import { ProfileContext } from '../../_context/ProfileContext';
import http from "../../_services/http-common";
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import 'moment/locale/id';


const Profile = () => {

    const [profile, setProfile] = useContext(ProfileContext);

    const [show, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [code, setCode] = useState('');

    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);


    const updateCode = e => {
        setCode(e.target.value)
    }

    const requestValidationEmailCode = e => {
        e.preventDefault();
        http.get('ess/profile/emailvalidation').then(response => {
            // console.log(response.data)
            if (response.data.status) {
                toast.success("Code sent successfully, please check your email")
                setShow(true)
            } else {
                toast.error("Sorry can't send validation code, please try later, or contact HR department")
            }
        }).catch(error => {
            toast.error("Sorry can't send validation code, please try later, or contact HR department")
        });

    }

    const submitEmailValidation = e => {
        e.preventDefault()
        if (code !== '') {
            setLoading(true)
            const params = new URLSearchParams()
            params.append('code', code)
            http.post('ess/profile/emailvalidation', params).then(response => {
                // console.log(response.data);
                if (response.data.status) {
                    toast.success("validation success")
                    setTimeout(() => {
                        window.location.reload(false)
                        setLoading(false)
                    }, 1500)

                } else {
                    toast.error("Sorry, the validation code you entered was not found")
                    setLoading(false)
                }
            }).catch(error => {
                toast.error("Sorry an error occurred, please try again later")
                setLoading(false)
            });

        } else {
            toast.warn("Please input the validation code")
        }

    }



    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12} sm={4} md={4}>
                    {(profile.photo_uri != '') ?
                        <Image className="rounded mx-auto d-block" src={profile.photo_uri} style={{ width: '100%', maxWidth: '173px' }} thumbnail />
                        :
                        <Image className="rounded mx-auto d-block" src="AppImages/brand/avatar6.png" style={{ width: '100%', maxWidth: '100px' }} />
                    }

                    <div className='mt-3' style={{ textAlign: 'center' }}>

                        <Button style={{ marginBottom: '15px' }} 
                        size="sm" href='/changefotoprofile'
                        variant="outline-secondary">Ubah Foto</Button>


                        <h3 style={{ marginBottom: '10px', fontFamily: 'MavenPro-SemiBold' }}>{profile.title_ahead} {profile.name} {profile.title_behind}</h3>
                        <div style={{ fontSize: '17px', color: '#717171' }}>NIK : {profile.nik}
                            <br />
                            <span style={{ color: '#2196f3' }}>{profile.email}
                                {/* {(profile.email_validation == 0) ? <Button variant="outline-secondary" onClick={requestValidationEmailCode} size="sm">Send validation</Button> : ''} */}
                            </span></div>
                    </div>
                </Col>
            </Row>

            <>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Email Validation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: 'center', color: '#607d8b' }}><h5>We have sent a validation code, please check your email.</h5></div>
                        <hr />
                        <FloatingLabel controlId="floatingPassword" label="Validation Code">
                            <Form.Control value={code} onChange={updateCode} type="text" />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button disabled={isLoading} variant="success" onClick={submitEmailValidation}>{isLoading ? 'Loading…' : 'Submit'}</Button>

                    </Modal.Footer>
                </Modal>
            </>

            <Row className="mt-4 mb-5 justify-content-md-center">
                <Col xs={12} sm={6} md={6}>
                    <Table striped gap={3} style={{ background: '#ffffff' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '30%' }}>Fingerprint ID</td>
                                <td style={{ width: '3%' }}>:</td>
                                <td>{profile.fingerprint_id}</td>
                            </tr>
                            <tr>
                                <td>KTP</td>
                                <td>:</td>
                                <td>{profile.ktp_number}</td>
                            </tr>
                            <tr>
                                <td>NPWP</td>
                                <td>:</td>
                                <td>{profile.npwp}</td>
                            </tr>
                            <tr>
                                <td>Akun Bank</td>
                                <td>:</td>
                                <td>{profile.bank_name} - {profile.bank_account_number}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Bergabung</td>
                                <td>:</td>

                                {(profile.join_date != '' && profile.join_date != null && profile.join_date != 'null' && profile.join_date != '0000-00-00')
                                    ? <td>
                                        <Moment format="DD MMMM YYYY" withTitle>
                                            {profile.join_date}
                                        </Moment>

                                        <Badge style={{ marginLeft: '14px' }} bg="secondary">
                                            {
                                                ((parseInt(profile.masa_kerja_y) > 0) ? profile.masa_kerja_y + ' tahun ' : '')
                                            }
                                            {
                                                ((parseInt(profile.masa_kerja_m) > 0) ? profile.masa_kerja_m + ' bulan ' : '')
                                            }
                                            {
                                                ((parseInt(profile.masa_kerja_d) > 0) ? profile.masa_kerja_d + ' hari ' : '')
                                            }
                                        </Badge>
                                    </td>
                                    : <td>-</td>
                                }


                            </tr>
                            <tr>
                                <td>Agama</td>
                                <td>:</td>
                                <td>{profile.religiion_name}</td>
                            </tr>
                            <tr>
                                <td>Golongan Darah</td>
                                <td>:</td>
                                <td>{profile.blood}</td>
                            </tr>
                            <tr>
                                <td>No HP</td>
                                <td>:</td>
                                <td>{profile.phone}</td>
                            </tr>
                            <tr>
                                <td>Tempat, Tanggal Lahir</td>
                                <td>:</td>
                                <td>{profile.place_of_birth}, <Moment format="DD MMMM YYYY" withTitle>{profile.date_of_birth}</Moment></td>
                            </tr>
                            <tr>
                                <td>Alamat</td>
                                <td>:</td>
                                <td>{profile.address}</td>
                            </tr>
                            <tr>
                                <td>Password</td>
                                <td>:</td>
                                <td>
                                    <Button href='/updatepassword' variant="outline-secondary" size="sm">
                                        Ubah Password
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Data Pribadi
                                </td>
                                <td>:</td>
                                <td>
                                    <div className='d-flex flex-column align-items-start'>
                                        <Button href='/changeprofile' variant="link" size="sm">
                                            Ubah Data Pribadi
                                        </Button>

                                        {/* <Button href='/changefamily' variant="link" size="sm">
                                            Ubah Data Keluarga
                                        </Button> */}

                                        <Button href='/historychangeprofile' variant="link" size="sm">
                                            Riwayat Perubahan
                                        </Button>
                                    </div>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </Table>




                    {(profile.positions && profile.positions.length > 0)
                        ?
                        <>
                            {profile.positions.map((position, idx) =>
                                <div key={idx}>
                                    <h4 style={{ fontFamily: 'MavenPro-SemiBold' }}>{(position.is_main == '1') ? 'Posisi Utama' : 'Posisi Lain'}</h4>
                                    <Table striped gap={3} style={{ background: '#ffffff' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '30%' }}>PT</td>
                                                <td style={{ width: '3%' }}>:</td>
                                                <td>{position.pt_name}</td>
                                            </tr>
                                            <tr>
                                                <td>PT Sub</td>
                                                <td>:</td>
                                                <td>{position.pt_sub_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Area</td>
                                                <td>:</td>
                                                <td>{position.pt_sub_area_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Departemen</td>
                                                <td>:</td>
                                                <td>{position.department_name}</td>
                                            </tr>
                                            {(position.members.length > 0) ?
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div style={{ textAlign: 'center' }}>Team Saya</div>
                                                        <hr />
                                                        <Container>
                                                            <Row className="g-2">
                                                                {position.members.map((member, index) =>
                                                                    // <li>{member.position_name} - <span>({member.nik})</span> {member.name}</li>
                                                                    <Col key={index} md={4} xs={6}>
                                                                        <Card className="text-center" style={{ minHeight: '153px' }}>

                                                                            <Card.Body>
                                                                                <Image className="rounded mx-auto d-block" src="AppImages/brand/avatar6.png" style={{ width: '100%', maxWidth: '50px' }} />

                                                                                {/* <Badge bg="secondary">{member.position_name}</Badge> */}
                                                                                <div>{member.name}</div>
                                                                                <div style={{ fontSize: '11px', color: '#9e9e9e' }}>{member.nik}</div>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                )}

                                                            </Row>
                                                        </Container>
                                                    </td>
                                                </tr>

                                                : <tr><td colSpan={3}></td></tr>}
                                        </tbody>
                                    </Table>
                                </div>
                            )}

                        </>

                        : ''}

                </Col>
            </Row>

        </Container>

    );
};

export default Profile;